<template>
  <template v-if="isProd()">
    <LayoutAppAccessibility />
  </template>
</template>

<script setup lang="ts">
const { isVisible } = useChatBot()
const { scrollPos } = useScrollUtils()
const route = useRoute()

watch(scrollPos, (val) => {
  if (typeof window === "undefined") return
  const isScrollingClassExists = document.documentElement.classList.contains("scrolling")

  if (val > 70) {
    if (!isScrollingClassExists) document.documentElement.classList.add("scrolling")
  } else {
    if (isScrollingClassExists) document.documentElement.classList.remove("scrolling")
  }
})
</script>

<style lang="scss">
.u1st .u1st_accBtnText {
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 50% !important;

  .scrolling & {
    box-shadow: 0 0 5px 1px #ccc !important;
  }
}
.u1st {
  #u1stLogoContainer {
    right: unset !important;
  }
}

#greeting-message-container {
  @screen min-md {
    html & {
      top: unset !important;
      bottom: theme.$spacing-s !important;
      left: theme.$spacing-s !important;
    }
  }
}
</style>
