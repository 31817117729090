<template>
  <Fragment />
</template>

<script setup lang="ts">
const bp = useDisplay()
onMounted(() => {
  // Add forceDomain: "finqai.co.il" to the _u1stSettings object to be able to work on the accessibility button
  if (!bp.isMobile.value) {
    useHead({
      script: [
        {
          type: "text/javascript",
          crossorigin: "anonymous",
          tagPosition: "head",
          innerHTML: `
            var _u1stSettings = {
                accessibilityBtn: {
                    style: {
                        desktop: [
                            {
                            "background-color": "transparent",
                            "background-size": "cover",
                            "background-image":
                                "url(https://finqfinanceprodpublic.blob.core.windows.net/accessibility/accessibility-icon.svg)",
                            width: "30px",
                            height: "30px",
                            padding: "0px",
                            position: "fixed",
                            top: "unset",
                            bottom: "16px",
                            "font-size": "0px",
                            color: "transparent",
                            left: "20px",
                            },
                        ],
                        mobile: [
                            {
                            "background-color": "transparent",
                            "background-size": "cover",
                            "background-image":
                                "url(https://finqfinanceprodpublic.blob.core.windows.net/accessibility/accessibility-icon.svg)",
                            width: "30px",
                            height: "30px",
                            padding: "0px",
                            position: "fixed",
                            top: "unset",
                            bottom: "100px",
                            "font-size": "0px",
                            color: "transparent",
                            left: "16px",
                            },
                        ],
                        tablet: [
                            {
                            "background-color": "transparent",
                            "background-size": "cover",
                            "background-image":
                                "url(https://finqfinanceprodpublic.blob.core.windows.net/accessibility/accessibility-icon.svg)",
                            width: "30px",
                            height: "30px",
                            padding: "0px",
                            position: "fixed",
                            top: "unset",
                            bottom: "100px",
                            "font-size": "0px",
                            color: "transparent",
                            left: "84px",
                            },
                        ],
                    },
                },
            };
            var isActive = /u1stIsActive=1/.test(document.cookie);
            var script = document.createElement("script");
            script.id = "User1st_Loader";
            script.src = "https://fecdn.user1st.info/Loader/head";
            !isActive && (script.async = "true");
            var documentPosition = document.head || document.documentElement;
            documentPosition.insertAdjacentElement("afterbegin", script);`,
        },
      ],
    })
  }
})
</script>
